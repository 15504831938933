
<template>
  <v-container fluid>
    <v-card class="px-6">
      <v-card-title
        primary-title
        class="pb-3"
      >
      </v-card-title>
      <v-card-text class="pa-0">
        <div class="d-flex flex-row flex-md-row gap-150">
          <v-flex xs6>
            <v-skeleton-loader
              v-bind="attrs"
              type="table-heading"
            ></v-skeleton-loader>

            <v-skeleton-loader
              v-bind="attrs"
              type="table-heading"
            ></v-skeleton-loader>

            <v-skeleton-loader
              v-bind="attrs"
              type="table-heading"
            ></v-skeleton-loader>
          </v-flex>
          <v-flex xs6>
            <v-skeleton-loader
              v-bind="attrs"
              type="table-heading"
            ></v-skeleton-loader>
            <v-skeleton-loader
              v-bind="attrs"
              type="table-heading"
            ></v-skeleton-loader>
            <v-skeleton-loader
              v-bind="attrs"
              type="table-heading"
            ></v-skeleton-loader>
          </v-flex>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn depressed> </v-btn>
        <v-btn depressed></v-btn>
      </v-card-actions>
    </v-card>
  </v-container>

  <!-- <v-sheet class="pa-3">
    <v-skeleton-loader
      class="mx-auto"
      max-width="300"
      type="paragraph"
    ></v-skeleton-loader>
  </v-sheet> -->
</template>

<script>
export default {
  data: () => ({
    attrs: {
      class: "mb-6",
      boilerplate: true,
      elevation: 2,
    },
  }),
  inject: {
    theme: {
      default: { isDark: false },
    },
  },
};
</script>

<style scoped>
.gap-10 {
  gap: 10px;
}
.gap-150 {
  gap: 150px;
}
</style>